@layer components {
  .logo {
    font-family: "Varela Round", sans-serif;
  }
  .edu {
    font-family: 'Roboto', sans-serif;
  }
  .form-input {
    @apply focus:ring-transparent border-0 rounded-none border-b border-gray-300 focus:border-pink-600 !important;
  }
  .genFont {
    @apply tracking-wide text-lg;
  }
  .hover {
    @apply transform hover:scale-110 transition duration-300 ease-in-out;
  }
  .button {
    @apply px-8 lg:px-16 uppercase rounded transform hover:scale-110 transition duration-300 ease-in-out  bg-pink-600 text-center py-2 text-white;
  }
  .button:disabled {
    @apply bg-gray-400 hover:scale-100;
  }
  .btnTrans {
    @apply bg-transparent border rounded-md py-1.5 font-medium transform transition duration-500;
  }
  .navLink {
    @apply h-full text-gray-400 flex items-center hover:text-blueish;
  }
  .navLink.active {
    @apply text-blueish font-semibold;
  }
  .sideBar {
    @apply px-2 py-3;
  }
  .sideBar.active {
    @apply text-blueish font-semibold;
  }
  /* dropdown menu */
  .menu-container {
    @apply relative flex items-center justify-center h-full;
  }
  .menu-trigger {
    @apply h-full;
  }
  .menu-trigger span {
    @apply font-bold align-middle text-sm mx-2.5;
  }
  /* .menu-trigger img {
        @apply rounded-full;
    } */
  .menu {
    @apply bg-white rounded absolute z-10 opacity-0 transform -translate-y-5 invisible;
    right: -15px;
    width: 200px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    transition: opacity 0.2s ease, transform 0.2s ease, visibility 0.2s;
  }

  .menu.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
  .menu ul {
    @apply list-none p-0 m-0;
  }

  .menu li a {
    @apply no-underline text-gray-700 text-sm p-2 px-3 border-b border-gray-300 flex items-center hover:text-blue-900;
  }
  .menu .img {
    @apply w-1/5;
  }
  .message {
    @apply opacity-100 transform transition ease-in-out duration-500 mt-2 font-medium capitalize rounded-sm text-sm text-center text-white p-1.5;
  }
  .confirmDelete {
    @apply p-1 rounded transition transform ease-in-out duration-300 hover:scale-125;
  }
}
