/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Glacial Indifference', sans-serif;                                              
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
input:-webkit-autofill {
  box-shadow: inset 0 0 0px 9999px white !important;
  -webkit-box-shadow: inset 0 0 0px 9999px white !important;
}
